.app {
	font-family: sans-serif;
	min-width: 300px;
	max-width: 600px;
	margin: 50px auto;
}

.app .gutter-left {
	margin-left: 9px;
}

.app .col-span-2 {
	grid-column: span 2;
}

.app .flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.app header {
	background-color: #143055;
	color: white;
	padding: 5px;
	border-radius: 3px;
}

.app main {
	padding: 0 36px;
}

.app p {
	text-align: center;
}

.app h1 {
	text-align: center;
	margin-left: 18px;
	font-size: 24px;
}

.app h2 {
	text-align: center;
	font-size: 20px;
	margin: 40px 0 10px 0;
}

.app .resources {
	text-align: center;
	list-style: none;
	padding: 0;
	display: grid;
	grid-gap: 9px;
	grid-template-columns: 1fr 1fr;
}

.app .resource {
	color: #0094ba;
	height: 36px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	padding: 3px 9px;
	text-decoration: none;
}

.app .resource:hover {
	background-color: rgba(68, 138, 255, 0.04);
}

.app pre {
	padding: 9px;
	border-radius: 4px;
	background-color: black;
	color: #eee;
}

.app details {
	border-radius: 4px;
	color: #333;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0.12);
	padding: 3px 9px;
	margin-bottom: 9px;
}

.app summary {
	outline: none;
	height: 36px;
	line-height: 36px;
}

.app .github-star-container {
	margin-top: 12px;
	line-height: 20px;
}

.app .github-star-container a {
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #333;
}

.app .github-star-badge {
	color: #24292e;
	display: flex;
	align-items: center;
	font-size: 12px;
	padding: 3px 10px;
	border: 1px solid rgba(27, 31, 35, 0.2);
	border-radius: 3px;
	background-image: linear-gradient(-180deg, #fafbfc, #eff3f6 90%);
	margin-left: 4px;
	font-weight: 600;
}

.app .github-star-badge:hover {
	background-image: linear-gradient(-180deg, #f0f3f6, #e6ebf1 90%);
	border-color: rgba(27, 31, 35, 0.35);
	background-position: -0.5em;
}
.app .github-star-badge .material-icons {
	height: 16px;
	width: 16px;
	margin-right: 4px;
}

.simplebar-content {
	height: 100%;
}

.light-bar .simplebar-scrollbar::before {
	background-color: #fffbff;
}

.filepond--root,
.filepond--root .filepond--drop-label {
	height: 200px;
}

.MuiBox-root:has(> .MuiButton-root.Mui-disabled),
.MuiInputBase-root.Mui-disabled,
.MuiInputBase-root.Mui-disabled input {
	cursor: not-allowed !important;
}

.notistack-SnackbarContainer {
	bottom: 40px !important;
}

.PrivateSwipeArea-root {
	display: none;
}
